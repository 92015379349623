<template>
  <v-container>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="Tabulator - Developer Documentation" />
    <!--EOC-->
    <v-row>
      <v-col cols="12" sm="3">
        <TheLeftSidebar :leftMenuItems="leftmenu"></TheLeftSidebar>
      </v-col>

      <v-col cols="12" sm="9">
        <v-card min-height="70vh" rounded="lg">
          <v-card-title>User Editing Data</v-card-title>
          <v-card-text>
            <p>
              Tabulator allows for users to edit the data contained in each cell
              of the table.
            </p>

            <p>
              Columns of the table can be set as editable using the
              <strong>editor</strong> property in the column definition.
            </p>

            <p>
              When a user clicks on an editable column the will be able to edit
              the value for that cell.
            </p>
            <p>
              By default Tabulator will use an editor that matches the current
              formatter for that cell. if you wish to specify a specific editor,
              you can set them per column using the <b>editor </b>option in the
              column definition. Passing a value of <b>true</b> to this option
              will result in Tabulator applying the editor that best matches the
              columns formatter, if present.
            </p>
            <p>
              You can pass an optional additional parameter with the editor,
              <b>editorParams </b> that should contain an object with additional
              information for configuring the editor.
            </p>

            <p>
              Example code, (you have to add this parameters inside the headers
              object. Please refer column section)
            </p>

            <CopyToClipBoard :text-to-copy="cellEditExample"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ cellEditExample }}</pre>
            </VueCodeHighlight>

            <h6 style="margin: 20px 0px 20px">
              Example Table with editable cells
            </h6>

            <!-- <h6>Search Record</h6>

            <p>
              You can search record by using search box. Please enter the search
              value and press enter.
            </p>

            <p>
              <v-text-field
                v-model="searchValue"
                label="Search"
                outlined
                dense
                @keyup.enter="searchRecord"
              ></v-text-field>
              <b>Search Value:</b> {{ this.searchValue }}
            </p> -->

            <div>
            <div id="pagination-counter" style="float: left; margin-right: 2px;"></div>
            <span id="record-count" style="float: left;font-size: 14px; margin-top: 2px;"></span>
            <div style="clear: both;"></div>
          </div>

            <BreadTabulator
              ref="tabulatorDataTable"
              layout="fitColumns"
              :autoColumns="false"
              :paginationSize="this.paginationSize"
              :paginationSizeSelector="this.paginationSizeSelector"
              :apiUrl="this.apiGetBrowseUrl()"
              :columns="this.columns"
              modelKey="demoProduct"
              @updateEditedCellData="updateEditedCellData"
            ></BreadTabulator>

            <h6>Built In Editors</h6>
            <p>
              Tabulator comes with a number of pre-built editor types that can
              be used to edit the data in your table.
            </p>

            <p>
              These can be set on a column by column basis using the
              <b>editor</b> property in the column definition.
            </p>

            <p>
              for more details please refer this URL
              <a
                href="https://tabulator.info/docs/5.5/edit#edit-builtin"
                target="_blank"
                >Built In Editors</a
              >
            </p>

            <h6>Used Code Structure</h6>

            <p>1. Used emit method to pass data from BreadTabulator common compnent to vue file</p>

            <CopyToClipBoard :text-to-copy="emitDataToVue"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ emitDataToVue }}</pre>
            </VueCodeHighlight>

            <p>2. In the vue file we have to do following changes to update the cell edited values in the database</p>
            <p>2.1 - add followng click event to BreadTabulator component</p>

            <CopyToClipBoard :text-to-copy="clickEvent"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ clickEvent }}</pre>
            </VueCodeHighlight>

            <CopyToClipBoard :text-to-copy="breadTabulatorComponent"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ breadTabulatorComponent }}</pre>
            </VueCodeHighlight>

            <p>2.2 - add followng method to vue file</p>

            <CopyToClipBoard :text-to-copy="clickEventMethod"></CopyToClipBoard>
            <VueCodeHighlight language="javascript">
              <pre>{{ clickEventMethod }}</pre>
              UPDATE API URL - `${this.$service[this.service.key]}/v1/en/console/model/${this.model.key}/${editedRecordId}/update`
            </VueCodeHighlight>



          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
      
      <script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
import TheLeftSidebar from "../components/PageDeveloperTabulator/TheLeftSidebar.vue";
import leftmenu from "../config/leftmenu.js";
import Service from "@/objects/service";
import CopyToClipBoard from "../components/PageDeveloperTabulator/CopyToClipBoard.vue";
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/prism-okaidia.css";
//import DeveloperRouter from "../../developer/router/index.js";
/* import "vue-code-highlight/themes/window.css"; */
//BOC:[model]
import Model from "@/objects/model";
//EOC

//BOC:[api]
import Api from "@/objects/api";
//EOC

import { mapState } from "vuex";
export default {
  components: {
    TheLeftSidebar,
    CopyToClipBoard,
    VueCodeHighlight,
  },
  computed: mapState({
    //
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    leftmenu: leftmenu,

    //BOC:[api]
    api: new Api(),
    //EOC

    //BOC:[service]
    service: new Service(),
    //EOC

    //BOC:[model]
    model: new Model(),
    //EOC

    filters: [],

    conditions: [],

    /* searchValue: "", */

    cellEditExample: `{title:"Rating", field:"rating", editor:"star", editorParams:{stars:4}}`,
    paginationSizeSelector: [10, 20, 30, 40, 50],
    paginationSize: 10,
    columns: [],
    emitDataToVue: `this.$emit("updateEditedCellData", cell);`,
    clickEvent: `@updateEditedCellData="updateEditedCellData"`,
    breadTabulatorComponent: `
    <BreadTabulator
              ref="tabulatorDataTable"
              layout="fitColumns"
              :autoColumns="false"
              :paginationSize="this.paginationSize"
              :paginationSizeSelector="this.paginationSizeSelector"
              :apiUrl="this.apiGetBrowseUrl()"
              :columns="this.columns"
              modelKey="demoProduct"
              @updateEditedCellData="updateEditedCellData"
            ></BreadTabulator>`,
    clickEventMethod:`
    updateEditedCellData(cell) {
      let editedRecordId = cell.getRow().getData().id;
      let editedRecordField = cell.getField();
      let editedRecordValue = cell.getValue();

      if (editedRecordField !== undefined) {
        var responseData = {
          [editedRecordField]: editedRecordValue,
        };

        this.api.setMethod("POST");
        this.api.setParams({
          data: JSON.stringify(responseData),
        });
        this.api.setUrl(
          UPDATE API URL
        );

        this.api.setCallbackCompleted(() => {
          this.$store.dispatch("showMessage", {
                    message: "Updated successfully.",
                    messageType: "success",
                    timeout:2000
                });
        });

        this.api.fetch();
      }
    }`
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Developer",
      to: { name: "PageServiceDashboard", params: { serviceKey: "developer" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Components",
      to: { name: "PageDeveloperComponent" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "BreadTabulator",
      to: { name: "PageDeveloperComponentBreadTabulator" },
      exact: true,
    });

    this.breadcrumb.add({
      text: "Edit Cell",
      to: { name: "" },
      exact: true,
    });
    //EOC

    //BOC:[model]
    this.model.getByKey("demoProduct");
    //EOC
    this.columns = this.$_.filter(
      this.model.browse.table.headers,
      (headers) => {
        return headers;
      }
    );

    this.columns.push({
      title: "Actions",
      filterable: false,
      headerSort: false,
      editor: "list",
      editorParams: {
        values: [
          {
            label: "View",
            value: "view",
          },
          {
            label: "Edit",
            value: "edit",
          },
          {
            label: "Delete",
            value: "delete",
          },
        ],
      },
      formatter: () => {
        return `<span class="">Action</span>`;
      },

      cellEdited: (cell) => {
        var cellValue = cell.getValue();
        switch (cellValue) {
          case "view":
            this.$router.push({
              name: "PageDeveloperComponentBreadTabulatorColumnsView",
              params: {
                columnKey: cell.getRow().getData().id,
              },
            });
            break;
          case "edit":
            alert("Editing row data");

            break;
          case "delete":
            alert("Deleting row data");
            break;
          default:
            break;
        }
      },
    });

    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
  },
  mounted() {
    //
  },
  methods: {
    apiGetBrowseUrl() {
      return `${this.$service[this.service.key]}/v1/en/console/model/${
        this.model.key
      }/list`;
    },

    updateEditedCellData(cell) {
      let editedRecordId = cell.getRow().getData().id;
      let editedRecordField = cell.getField();
      let editedRecordValue = cell.getValue();

      if (editedRecordField !== undefined) {
        var responseData = {
          [editedRecordField]: editedRecordValue,
        };

        this.api.setMethod("POST");
        this.api.setParams({
          data: JSON.stringify(responseData),
        });
        this.api.setUrl(
          `${this.$service[this.service.key]}/v1/en/console/model/${
            this.model.key
          }/${editedRecordId}/update`
        );

        this.api.setCallbackCompleted(() => {
          this.$store.dispatch("showMessage", {
                    message: "Updated successfully.",
                    messageType: "success",
                    timeout:2000
                });
        });

        this.api.fetch();
      }
    },

    /* searchRecord() {
      this.$refs.tabulatorDataTable.tabulator.setFilter(
        "name",
        "like",
        this.searchValue
      );
    }, */
  },
};
</script>
    
    <style scoped>
</style>